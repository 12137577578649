import request from '../utils/request'

export const GetBanner=()=>{     //首页轮播图
    return request.get(`/api/banner/list`)
}

//获取科目列表
export const GetCategories=()=>{

    return request.get('/api/accountName/getAccount')
};

//获取章节列表
export const GetChapterList=(cid)=>{

    return request.get(`/api/category/getZj?kmmcid=${cid}`)
};

export const GetArticleList=(limit,page,categoryId,title,type)=>{  //文章列表
    return request.get(`/api/article/list?limit=${limit}&page=${page}&accountId=${categoryId}&title=${title}&type=${type}`)
}
export const GetArticle=(id)=>{     //文章详情
    return request.get(`/api/article/getById/${id}`)
}

//文章收藏
export const AericleCollect=(data)=>{

    return request.post(`/api/articleCollect/saveOrDeleteCollect`,data)
}

export const GetNewsList=(limit,page)=>{  //新闻列表
    return request.get(`/api/news/list?limit=${limit}&page=${page}`)
}

export const GetNews=(id)=>{     //新闻详情
    return request.get(`/api/news/getById/${id}`)
}
export const GetTryexamList=(typeId,limit,page)=>{  //模拟考试列表
    return request.get(`/api/testPaper/getSimulation?typeId=${typeId}&limit=${limit}&page=${page}`)
}
export const GetTryList=()=>{  //模拟考试分类列表
    return request.get(`/api/testPaper/getTestCategory`)
}

//获取课程列表
export const GetCourseList=(page,pageSize,categoryId,title,type)=>{
    
    return request.get(`/api/videoCourse/getCourseList?page=${page}&limit=${pageSize}&accountId=${categoryId}&title=${title}&type=${type}`)
}  

//获取课程详情
export const GetCourseDetail=(courseId)=>{

    return request.get(`/api/videoCourse/getIntroduction?courseId=${courseId}`)
}
  //保存文章学习时长记录
export const PostArticeSave=(pass)=>{ 
    return request.post(`/api/learnDuration/save`,pass)
}

//保存视频笔记
export const SaveVideoNote=(data)=>{

    return request.post('/api/videoNote/insertVideoNote',data)
}

//获取视频笔记列表
export const GetVideoNoteList=(videoId,page,pageSize)=>{

    return request.get(`/api/videoNote/getVideoNote?videoId=${videoId}&page=${page}&limit=${pageSize}`)
}

//加入或删除课程学习
export const JoinOrDelCourse=(data)=>{

    return request.post('api/videoCollect/collectCourse',data)
}

  //获取课程视频列表
export const GetVideoList=(courseId)=>{ 
    return request.get(`api/video/getVideoList?courseId=${courseId}`)
}
export const GetRecordList=(limit,page)=>{  //答题记录列表
    return request.get(`/api/racord/records?limit=${limit}&page=${page}`)
}
export const GetNoticeList=(limit,page)=>{  //通知公告列表
    return request.get(`/api/notice/list?limit=${limit}&page=${page}`)
}
export const GetNotice=(id)=>{     //通知公告详情
    return request.get(`/api/notice/getById/${id}`)
}
export const GetByKey=()=>{  //首页通知公告
    return request.get(`/api/notice/getByKey`)
}
export const GetByKeyDetail=()=>{  //首页通知公告详情
    return request.get(`/api/notice/getByKeyDetail`)
}
export const GetUnitByKey=()=>{  //单位介绍
    return request.get(`/api/unitIntro/getByKey`)
}
export const Getstatics=()=>{  //学习时长记录
    return request.get(`/api/learnDuration/statics`)
}
export const SaveCourseTotalTime=(data)=>{   //保存课程学习时长记录
    return request.post(`/api/videoLearn/updateUserTime`,data)
}
export const GetDict=(id)=>{     //字典
    return request.get(`/api/dict/getDict/${id}`)
}

//获取用户错题、收藏等数据数
export const GetUserPracticeCount=(accountId)=>{
    return request.get(`/api/homePage/getUserPracticeInfo?accountId=${accountId}`)
}

//获取用户笔记列表
export const GetUserNote=(accountId,page,pageSize)=>{

    return request.get(`/api/note/getNote?page=${page}&limit=${pageSize}&accountId=${accountId}`)
}

//获取单个用户单个视频笔记
export const GetVideoNote=(videoId)=>{
    return request.get(`/api/videoNote/getUserNote?videoId=${videoId}`)
}

//删除笔记
export const DelNote=(id)=>{

    return request.delete(`/api/note/deleteNote?id=${id}`)
}

//保存已完成的学习视频记录
export const SaveFinishedLearnVideo=(data)=>{

    return request.post('/api/videoLearn/insertVideoLearn',data)
}

//保存当前视频播放时间，做记忆播放
export const SaveCurrentVideoTime=(data)=>{

    return request.post('/api/videoSchedule/insertLearnSchedule',data)
}